import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import VideoPlayer from '../../components/VideoPlayer'
import { useEffect } from 'react';

export type StoredValue = string;

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer(doIntro: String) {
    await storeAnswer('78', t('screens:78.uuid'), doIntro as StoredValue);
    navigate('/session/rotate-instruction/intro', { replace: true });
  }

  async function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    props.useAudio('screens:78.audio');
  });

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" onClick={() => answer('true')} />  
  </>);

  return (
    <SessionScreen
      screen="78"
      title={`${t('screens:78.step')} ${t('screens:78.title')}`}
      chapter={1}
      progress={30}
      audio={t('screens:78.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:78.text')} />
      <VideoPlayer src={t('screens:78.source')} poster={t('screens:78.poster')} />
      <div> </div>
      <Markdown value={t('screens:78.bottomText')} />
      {/* <span> <a href='6' style={{ color: "#75C6CB" }}><b><u>{t("skip_introduction_practice")}</u></b></a></span> */}
    </SessionScreen>
  );
};
