import { getLatestScreenData, getSession } from '../../services/manager';
import { ReactComponent as Visual } from '../../assets/rotate-instruction.svg';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from '../../components/Markdown';
import useOrientation from '../../hooks/useOrientation';
import useStoreScreenView from '../../hooks/useStoreScreenView';

export default function RotateInstructionScreen() {
  const { i18n, t } = useTranslation();
  const orientation = useOrientation();

  useStoreScreenView('rotate_instruction');

  useEffect(() => {
    var searchParams = new URLSearchParams({
      Lang: i18n.language,
      SessionId: '2bbf5821-4534-4706-a56c-110da77e0335',
      Intro: "false",
    }).toString();
    var redirectUrl = 'https://moovd.nl/en/request-a-demo/';
    searchParams += '&RedirectUrl=' + redirectUrl;
    if (orientation === 'horizontal') {
      window.location.href = [process.env.REACT_APP_REDIRECT as string, searchParams].join('?');
    }
  }, [i18n.language, orientation]);

  return (
    <div className="min-h-screen pt-28 p-6 bg-blue flex flex-col">
      <h1 className="text-center text-3xl font-bold text-white mb-6 after:border-b after:block after:w-10 after:mx-auto after:pt-8">
        {t('screens:rotate_instruction.title')}
      </h1>

      <div className="px-6 text-white text-center text-xl">
        { orientation === 'vertical' && <Markdown value={t('screens:rotate_instruction.text')} /> }
        { orientation === 'horizontal' && <p>{t('starting_emdr_app_please_wait')}</p> }
      </div>

      { orientation === 'vertical' &&
        <div className="p-6 flex-1 flex items-center mx-auto max-w-xs">
          <Visual className="w-full" />
        </div>
      }
    </div>
  );
};
