import {
  getSession,
  clearSession,
  createSessionId,
  getLastViewedScreen,
  setSession,
} from "../services/manager";
import { ReactComponent as SpinnerIcon } from "../assets/icons/spinner.svg";
import { startSession } from "../services/api";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../assets/background.webp";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import ENFlag from "../assets/flags/en.svg";
import Logo from "../assets/logo.svg";
import NLFlag from "../assets/flags/nl.svg";
import RespondentTextField from "../components/RespondentTextField";
import PincodeNumberField from "../components/PincodeNumberField";
import type { AxiosError } from "axios";
import useValidation from "../hooks/useValidation";

export default function StartSessionScreen() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [respondentNumber, setRespondentNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [isAborted, setAborted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validation = useValidation(pincode, "numeric|size:5");
  const loadingDelay = useRef<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const validPincodes = [
    87409, 61496, 12229, 82431, 43465, 57227, 92715, 15761, 37900, 59245, 87503,
    65519, 36110, 43337, 82252, 10838, 78976, 65703, 27188, 90470, 13695, 44281,
    57824, 39056, 21573, 69420, 50736, 31847, 98754, 63215, 24783, 89506, 31064,
    72691, 14587, 51827, 37628, 96134, 40987, 75341, 62409, 53086, 18726, 67942,
    58236, 37645, 98471, 21375, 59468, 82075, 31548, 87612, 29380, 46912, 73596,
    82134, 65924, 52847, 18453, 71349, 93526, 24701, 63095, 51896, 29478, 16725,
    59876, 40192, 83645, 57934, 21396, 47523, 68905, 34168, 72504, 89470, 63287,
    51403, 36985, 14703, 65837, 32459, 76032, 54296, 18963, 67315, 29578, 84027,
    61528, 47390, 95621, 18735, 59834, 24690, 73016, 58439, 36905, 81452, 52039,
    17503, 64378, 42907, 56124, 94058, 28706, 71620, 39546, 80671, 54103, 29785,
    46038, 95820, 73514, 18250, 67439, 52386, 86429, 37915, 61597, 49836,
  ];

  useEffect(() => {
    if (getSession()) {
      setAborted(true);
    }
    console.log("env: " + process.env.REACT_APP_ENVIRONMENT);
    i18n.changeLanguage("");
  }, [i18n]);

  async function handleStartSession() {
    if (respondentNumber == "") {
      setErrorMessage(t("enter_your_name"));
      return;
    }

    setErrorMessage(validation.error);
    if (validation.failed) return;

    if (!validPincodes.find((p) => p == parseInt(pincode))) {
      setErrorMessage(t("pincode_not_valid"));
      return;
    }

    // Delay show of loading icon
    clearTimeout(loadingDelay.current);
    loadingDelay.current = window.setTimeout(() => setLoading(true), 1000);

    try {
      const sessionId = createSessionId();

      // Send request to the api, make sure we can connect.
      if (!process.env.REACT_APP_SKIP_START_SESSION) {
        await startSession(sessionId, respondentNumber, pincode);
      }

      setSession(sessionId);
      navigate("/session/welcome");
    } catch (e) {
      const error = e as AxiosError;
      if (error.isAxiosError) {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleStartNewSession() {
    await clearSession();
    setAborted(false);
  }

  async function handleContinueSession() {
    const viewData = await getLastViewedScreen();
    if (viewData) navigate(viewData.path, { replace: true });
  }

  const loadingIcon = isLoading ? (
    <SpinnerIcon className="animate-spin h-5 absolute left-0" />
  ) : undefined;

  function getVersion() {
    if (
      process.env.REACT_APP_ENVIRONMENT === "DEV" ||
      process.env.REACT_APP_ENVIRONMENT === "QA"
    )
      return (
        <div className="text-center">v{process.env.REACT_APP_VERSION}</div>
      );
  }

  return (
    <div
      className="min-h-screen pt-28 p-12 flex flex-col gap-6 justify-between items-center bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {isAborted && <div className="absolute inset-0 bg-skyblue/50"></div>}

      <div className="relative text-center">
        <img
          src={Logo}
          alt="Moovd logo"
          className="inline-block mb-2 h-10"
          width="153"
          height="40"
        />
        <h1 className="text-4xl font-bold">Digital EMDR</h1>
      </div>

      <div className="relative flex flex-col flex-1 justify-center gap-6 max-w-xs">
        {isAborted && (
          <>
            <h2 className="text-2xl font-bold text-center">
              {t("your_session_has_been_aborted_but_not_yet_completed")}
            </h2>
            <Button
              label={t("continue_session")}
              variant="quaternary"
              size="large"
              shadow
              onClick={() => handleContinueSession()}
              className="w-full flex-none"
            />
            <button
              className="underline text-center"
              onClick={() => handleStartNewSession()}
            >
              {t("or_start_a_new_session")}
            </button>
          </>
        )}

        {!isAborted && (
          <>
            <div className="relative text-center pin">
              <h1 className="text-xl font-bold">
                {t(
                  "enter_the_name_of_your_therapist_and_the_pincode_for_your_organisation_below"
                )}
              </h1>
            </div>
            <div className="w-73">
              <RespondentTextField
                value={respondentNumber}
                onChange={setRespondentNumber}
              />
            </div>
            <div className="w-73">
              <PincodeNumberField value={pincode} onChange={setPincode} />
            </div>

            {errorMessage && (
              <Dialog
                actions={
                  <Button
                    onClick={() => setErrorMessage(null)}
                    label={t("close")}
                  />
                }
              >
                {t("Error: ")} {errorMessage}
              </Dialog>
            )}

            <Button
              label={t("start")}
              variant="quaternary"
              size="large"
              shadow
              onClick={handleStartSession}
              icon={loadingIcon}
            />
          </>
        )}

        <div className="relative text-center mt-6">
          <p className="text-lg mb-2">{t("choose_language")}</p>
          <img
            onClick={() => i18n.changeLanguage("nl")}
            src={NLFlag}
            alt="Dutch"
            width="32"
            height="32"
            className="h-8 inline-block mx-1 cursor-pointer"
          />
          <img
            onClick={() => i18n.changeLanguage("en")}
            src={ENFlag}
            alt="English"
            width="32"
            height="32"
            className="h-8 inline-block mx-1 cursor-pointer"
          />
        </div>
        {getVersion()}
      </div>
    </div>
  );
}
